import { VConfirmDialog } from "@/models";
import router from "@/router";
import { ability, operationsService, rentalsService } from "@/services";
import {
  accountingPeriodsStore,
  coreStore,
  documentsStore,
  fixedAssetsStore,
  partnersStore,
  productsStore,
  realEstateAssetsStore,
  realEstateLoansStore,
  rentalAgreementsStore,
  tenantsStore,
  transactionsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ForbiddenError, subject } from "@casl/ability";
import {
  CategorizationEntry,
  LedgerAccountEnum,
  PartnerTypeEnum,
  RealEstateLoan,
  Suggestion,
  TenantTypeEnum,
  TypeReference,
  categorizationRules,
  distanceAmount,
  getLoanTypeText,
  getMoment,
  getMonthlyPayment,
  isLoanTypeAutomatized,
  round2decimals,
} from "@edmp/api";
import { SetupContext, computed } from "@vue/composition-api";
import Decimal from "decimal.js-light";
import { cloneDeep, flatMap, get } from "lodash";
import {
  TransactionState,
  useTransaction,
} from "../transaction/transaction.usable";
import { useTransactions } from "../transactions.usable";
import CategorizationValidateCategories, {
  CategoryValidate,
} from "./categorizationValidateCategories.usable";

export const useCategorization = (
  transactionState: TransactionState,
  context: SetupContext
) => {
  const { getAttribute } = useTransactions();
  const {
    product,
    transactionAmountTaxDecomposition,
    getTransactionWithNewCategories,
    refreshTransaction,
  } = computed(() => useTransaction(transactionState, context)).value;

  /**
   * Data
   */
  const categoriesList = computed(() => transactionsStore.categoriesList);

  /**
   * * Store
   * Use for provide store action to component
   */
  const updateCategoriesList = async () => {
    return await transactionsStore.updateCategoriesList();
  };

  /**
   * * transactionState
   *
   * Use for get and update element in transactionState
   */
  const categories = computed({
    get: () => transactionState.lines,
    set: (lines) =>
      context.emit(
        "update:transactionState",
        Object.assign(transactionState, { lines })
      ),
  });

  const selectedCategory = computed({
    get: () => transactionState.selectedCategory,
    set: (selectCategory) =>
      context.emit(
        "update:transactionState",
        Object.assign(transactionState, { selectedCategory: selectCategory })
      ),
  });

  const isOpenCategorizationList = computed({
    get: () => transactionState.isOpenCategorizationList,
    set: (isOpen) =>
      context.emit(
        "update:transactionState",
        Object.assign(transactionState, { isOpenCategorizationList: isOpen })
      ),
  });

  const isOpenCategorizationDetailStep = computed({
    get: () => transactionState.isOpenCategorizationDetailStep,
    set: (isOpen) =>
      context.emit(
        "update:transactionState",
        Object.assign(transactionState, {
          isOpenCategorizationDetailStep: isOpen,
        })
      ),
  });

  const isOpenCategorizationDuplicate = computed({
    get: () => transactionState.isOpenCategorizationDuplicate,
    set: (isOpen) =>
      context.emit(
        "update:transactionState",
        Object.assign(transactionState, {
          isOpenCategorizationDuplicate: isOpen,
        })
      ),
  });

  const isUpdatingCategorization = computed({
    get: () => transactionState.isUpdatingCategorization,
    set: (isUpdate) =>
      context.emit(
        "update:transactionState",
        Object.assign(transactionState, { isUpdatingCategorization: isUpdate })
      ),
  });

  /**
   * * Use
   */
  /**
   * AddCategory takes a CategorizationEntry and adds it to the beginning of the categories array.
   */
  const addCategory = (category: CategorizationEntry) => {
    categories.value.unshift(cloneDeep(category));
  };

  /**
   * It takes an index and a category, and if the index is not -1, it replaces the category at that index
   * with the new category
   */
  const updateCategory = (index: number, category: CategorizationEntry) => {
    if (index !== -1) {
      const lines = cloneDeep(categories.value);
      lines[index] = category;
      categories.value = lines;
    }
  };

  /**
   * It deletes a category from the list of categories
   */
  const deleteCategory = (index: number) => {
    categories.value.splice(index, 1);
    if (!categories.value.length) {
      isOpenCategorizationList.value = true;
      isOpenCategorizationDetailStep.value = false;
    }
    if (categories.value.length === 1) {
      const lines = cloneDeep(categories.value);
      lines[0].amount = transactionState.transaction.value.amount;
      categories.value = lines;
    }
  };

  /**
   * It resets the categories to the saved categories
   */
  const resetCategories = () => {
    categories.value = cloneDeep(transactionState.savedCategories);
    if (!categories.value.length) {
      isOpenCategorizationList.value = true;
      isOpenCategorizationDetailStep.value = false;
    }
  };

  /**
   * It opens the categorization list and closes the categorization detail
   */
  const subDivide = () => {
    isOpenCategorizationList.value = true;
    isOpenCategorizationDetailStep.value = false;
  };

  /**
   * If the transaction state exists, and the transaction has a rent receipt, then open the rent receipt
   */
  const openRentReceipt = () => {
    try {
      ForbiddenError.from(ability).throwUnlessCan(
        "rentReceipt",
        subject("RentalManagement", {})
      );
      const rentReceipt = !!categories.value.filter(
        ({ account, amount }) =>
          account.substring(0, 6) === "706000" &&
          new Decimal(amount).isPositive()
      ).length;
      if (rentReceipt) {
        context.emit("update:isOpenRentReceiptStep", 1);
      }
    } catch (error) {
      if (error instanceof ForbiddenError) {
        return;
      }
    }
  };

  const downloadRentReceipt = async (lines) => {
    const tenantId = lines.find(
      (line) => line.account.substring(0, 6) === LedgerAccountEnum.N706000
    ).tenant;
    const rentAmount = lines.find(
      (line) => line.account.substring(0, 6) === LedgerAccountEnum.N706000
    )?.amount;
    const rentAmountCharge = lines.find(
      (line) => line.account.substring(0, 6) === LedgerAccountEnum.N708399
    )?.amount;
    const rentAmountTVA = lines.find(
      (line) => line.account.substring(0, 6) === LedgerAccountEnum.N445720
    )?.amount;
    const rentPeriod = transactionState.transaction.date.operation;
    let rentAmountTotal = new Decimal(rentAmount ?? 0).plus(
      rentAmountCharge ?? 0
    );
    if (rentAmountTVA) {
      rentAmountTotal = rentAmountTotal.plus(rentAmountTVA);
    }

    try {
      const currentAttachements = documentsStore.getDocumentByTransaction(
        transactionState.transaction.id
      );
      if (currentAttachements?.length) {
        await documentsStore.deleteDocument(currentAttachements[0].id);
      }
      if (tenantId) {
        if (!product.value?.id) {
          throw new Error("Cannot find product id in `product.value.id`");
        }
        await rentalsService.agreements.downloadReceipt({
          id: tenantId,
          productId: product.value.id,
          bankAccountId: transactionState.transaction.bankAccountId,
          transactionId: transactionState.transaction.id,
          rentPeriod: rentPeriod,
          rentAmount: rentAmount ?? 0,
          rentAmountCharge: rentAmountCharge ?? 0,
          rentAmountTVA: rentAmountTVA,
          rentAmountTotal: rentAmountTotal.toNumber(),
          dateOperation: transactionState.transaction.date.operation,
        });
      }
    } finally {
      documentsStore.fetchDocuments(productsStore.currentId);
      refreshTransaction();
    }
  };

  /**
   * It checks if there are any transactions with the same summary, amount and date as the current
   * transaction, and if there are, it opens the suggestions panel and refreshes the transactions
   */
  const checkOnNewSuggestions = async () => {
    const suggests = flatMap(transactionsStore.transactions).filter(
      (transaction) =>
        transaction.id !== transactionState.transaction.id &&
        transaction.summary === transactionState.transaction.summary &&
        transaction.value.amount ===
          transactionState.transaction.value.amount &&
        new Date(transaction.date.operation).getDate() ===
          new Date(transactionState.transaction.date.operation).getDate() &&
        !transaction.operations?.journalEntry?.lines
    );
    if (suggests.length) {
      for (const suggest of suggests) {
        await refreshTransaction(suggest.id);
      }
    }
  };

  /**
   * It takes an account number and returns the corresponding category information
   * @param {string} account - string - the account number
   * @returns The categoryInfos object
   */
  const getCategoryInfos = (account: string): Suggestion => {
    let categoryInfos: Suggestion | undefined;
    for (const parentCategories of Object.values(
      transactionsStore.categoriesList
    )) {
      categoryInfos = parentCategories.find(
        (category) => category.number === account
      );
      if (categoryInfos) break;
    }

    if (!categoryInfos) {
      coreStore.displayFeedback({
        type: FeedbackTypeEnum.ERROR,
        message: "Une erreur est survenue lors de la categorisation",
      });
      console.error(categoryInfos);
      throw new Error("no categoryInfos found");
    }

    return categoryInfos;
  };

  /**
   * It selects a category and opens the categorization detail step if authorized
   */
  const selectCategory = (category: Suggestion) => {
    try {
      ForbiddenError.from(ability).throwUnlessCan(
        "categorize",
        subject("Transaction", {
          categoryNumber: category.number,
        })
      );
      selectedCategory.value = category;
      isOpenCategorizationDetailStep.value = 1;
      isOpenCategorizationList.value = false;
    } catch (error) {
      if (error instanceof ForbiddenError) {
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.WARNING,
          message: error.message,
        });
      }
    }
  };

  /**
   * It saves the categorization of a transaction.
   */
  const saveCategorization = async () => {
    isUpdatingCategorization.value = true;

    // Check rules
    const { isEqualAmount } = categorizationRules;
    if (!isEqualAmount(transactionState.transaction, categories.value)) {
      const message = `La somme des opérations comptables doit être égale au montant de la transaction. 
      Veuillez corriger avant de valider ou voir plus d’infos dans cet article.`;
      coreStore.displayFeedback({
        message,
        type: FeedbackTypeEnum.ERROR,
        timeout: 15000,
      });
      isUpdatingCategorization.value = false;
      throw new Error(message);
    }

    // Remove accountName from state.lines
    // restLine = realEstateAsset, rentalUnit, client , partner
    const linesToUpdate = categories.value.map(
      ({ account, amount, ...restLine }) => {
        delete restLine.accountName;
        return {
          amount: amount,
          account: account.substring(0, 6),
          ...restLine,
        };
      }
    );

    try {
      await operationsService.create({
        productId: product.value.id,
        accountingPeriodId: accountingPeriodsStore.currentId,
        transactionId: transactionState.transaction.id,
        entries: linesToUpdate,
      });
      checkOnNewSuggestions().then();
      if (accountingPeriodsStore.isIS) {
        if (
          linesToUpdate.find(
            (line) =>
              line.account === LedgerAccountEnum.N706000 &&
              line.supportingDocument === undefined &&
              new Decimal(line.amount).isPositive()
          ) !== undefined
        ) {
          await downloadRentReceipt(linesToUpdate);
        }
      } else if (accountingPeriodsStore.isIR) {
        openRentReceipt();
      }
      await refreshTransaction(); // ! Re-init transactionState
    } catch (err) {
      resetCategories();
    }
  };

  /**
   * It validates the user's ability to suggest a categorization, then it resets the categorization of
   * the transaction and adds the suggested categorization
   */
  const validateSuggestion = async () => {
    try {
      ForbiddenError.from(ability).throwUnlessCan("suggest", "Transaction");
      for (const suggestedLine of transactionState.suggestedLines) {
        addCategory(suggestedLine);
      }
      await saveCategorization().then();
    } catch (error) {
      if (error instanceof ForbiddenError) {
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.WARNING,
          message: error.message,
        });
      }
    }
  };

  /**
   * It validates the category line of a transaction
   */
  const validateCategory = async (
    categoryValidate: CategoryValidate,
    confirmDialog: VConfirmDialog
  ) => {
    await new CategorizationValidateCategories(
      transactionState,
      categoryValidate,
      confirmDialog,
      context
    ).validateCategory();
  };

  /**
   * It updates the categories list, then it creates a new categoryValidate object, then it checks if
   * the transaction amount is positive or negative, then it sets the categoryValidate.category to
   * either 110001, then it validates the category, then it saves the categorization
   */
  const addManualTransactionFlow = async (confirmDialog: VConfirmDialog) => {
    if (router.currentRoute.query.addPreviousYearBalance === "true") {
      await updateCategoriesList();
      const category: Partial<CategoryValidate> = {
        category: getCategoryInfos(LedgerAccountEnum.N110001),
        references: {
          realEstateAsset: undefined,
          rentalUnit: undefined,
          rentalAgreement: undefined,
          tenant: undefined,
          partner: undefined,
          realEstateLoan: undefined,
          supportingDocument: undefined,
          fixedAsset: undefined,
          beneficiary: undefined,
        },
        optionalsCategories: new Map<LedgerAccountEnum, number>(),
      };
      if (category.category) {
        validateCategory(category as Required<CategoryValidate>, confirmDialog);
        saveCategorization().then();
      }
    }
  };

  const isTvaCollectedIncorrect = () => {
    const rent = transactionState.lines.find(
      (line) => line.account === LedgerAccountEnum.N706000
    );
    const tva = transactionState.lines.find(
      (line) => line.account === LedgerAccountEnum.N445720
    );
    const charges = transactionState.lines.find(
      (line) => line.account === LedgerAccountEnum.N708399
    );
    if (rent && tva) {
      if (charges) {
        return (
          new Decimal(rent.amount).add(charges.amount).toFixed(2) <
            new Decimal(tva.amount).mul(5).sub(0.04).toFixed(2) ||
          new Decimal(rent.amount).add(charges.amount).toFixed(2) >
            new Decimal(tva.amount).mul(5).add(0.04).toFixed(2)
        );
      } else {
        return (
          new Decimal(rent.amount).toFixed(2) <
            new Decimal(tva.amount).mul(5).sub(0.04).toFixed(2) ||
          new Decimal(rent.amount).toFixed(2) >
            new Decimal(tva.amount).mul(5).add(0.04).toFixed(2)
        );
      }
    } else return false;
  };

  /**
   * It returns the amount of money that is missing from the transaction
   */
  const getMissingAmount = () =>
    categorizationRules.getMissingAmount(
      transactionState.transaction,
      categories.value
    );

  const isRequired = (name: TypeReference, category?: Suggestion): boolean => {
    return get(
      category ? category : transactionState.selectedCategory,
      "required",
      new Array<string>()
    ).includes(name);
  };

  const isOptional = (name: TypeReference, category?: Suggestion): boolean => {
    return (
      get(
        category ? category : transactionState.selectedCategory,
        "fields",
        new Array<string>()
      ).includes(name) && !isRequired(name, category)
    );
  };

  const getRentalUnitFromRealEstateAssetId = (realEstateAssetId: string) =>
    realEstateAssetsStore.getRentalUnitByRealEstateAssetId(realEstateAssetId);

  const isSamePeriodAsAcquisition = (date): boolean =>
    getMoment(date).isBetween(
      accountingPeriodsStore.currentAccountingPeriod?.startAt,
      accountingPeriodsStore.currentAccountingPeriod?.endAt,
      undefined,
      "[]"
    );

  /**
   * References
   */
  const realEstateAssets = (category?: Suggestion) => {
    const realEstateAssets = realEstateAssetsStore.realEstateAssets
      .filter((realEstateAsset) => {
        if (
          (category
            ? category.number
            : transactionState.selectedCategory?.number) ===
          LedgerAccountEnum.N213000
        ) {
          if (!isSamePeriodAsAcquisition(realEstateAsset.boughtAt)) {
            return false;
          }
        }
        return true;
      })
      .map((realEstateAsset) => ({
        name: realEstateAsset.name,
        id: realEstateAsset.id,
      }));
    if (isOptional(TypeReference.realEstateAsset, category)) {
      return [{ name: "Aucun", id: "" }, ...realEstateAssets];
    }
    return realEstateAssets;
  };
  const fixedAssets = (category?: Suggestion) => {
    const fixedAssets = fixedAssetsStore.fixedAssets
      .filter((fixedAsset) => {
        if (
          getMoment(fixedAsset.boughtAt).isBetween(
            getMoment(accountingPeriodsStore.currentAccountingPeriod?.startAt),
            getMoment(accountingPeriodsStore.currentAccountingPeriod?.endAt),
            "days",
            "[]"
          )
        ) {
          return fixedAsset;
        }
      })
      .map((fixedAsset) => ({
        name: fixedAsset.name,
        id: fixedAsset.id,
      }));
    if (isOptional(TypeReference.fixedAsset, category)) {
      return [{ name: "Aucun", id: "" }, ...fixedAssets];
    }
    return fixedAssets;
  };

  const rentalAgreements = (
    realEstateAssetId?: string,
    category?: Suggestion
  ) => {
    const rentalAgreements = realEstateAssetId
      ? rentalAgreementsStore
          .getRentalAgreementsByRealEstateAssetId(realEstateAssetId)

          .map((rentalAgreement) => {
            return {
              name: rentalAgreement.name,
              id: rentalAgreement.id as string,
            };
          })
      : [];
    if (isOptional(TypeReference.rentalAgreement, category)) {
      return [{ name: "Aucun", id: "" }, ...rentalAgreements];
    }
    return rentalAgreements;
  };

  const tenants = (realEstateAssetId?: string, category?: Suggestion) => {
    const tenants = realEstateAssetId
      ? tenantsStore
          .getTenantsByRealEstateAssetId(realEstateAssetId)

          .map((tenant) => {
            if (tenant.type === TenantTypeEnum.NATURAL_PERSON) {
              return {
                name: `${tenant.firstName} ${tenant.lastName}`,
                id: tenant.id as string,
              };
            } else
              return {
                name: `${tenant.denomination}`,
                id: tenant.id as string,
              };
          })
      : [];
    if (isOptional(TypeReference.tenant, category)) {
      return [{ name: "Aucun", id: "" }, ...tenants];
    }
    return tenants;
  };

  const partners = () =>
    partnersStore.partners.map((e) => ({
      name:
        e.type === PartnerTypeEnum.LEGAL_PERSON
          ? e.denomination || e.siret || "Unknown"
          : e.type === PartnerTypeEnum.NATURAL_PERSON
          ? `${e.firstName} ${e.lastName}`
          : "Unknown",
      id: e.id,
    }));

  const loanTitle = (loan: RealEstateLoan): string => {
    if (isLoanTypeAutomatized(loan.loanType)) {
      return `${loan.name} (${round2decimals(
        getMonthlyPayment(loan)
      )} €, le ${new Date(loan.loanStartAt).getDate()} du mois)`;
    } else {
      return `${loan.name} (${getLoanTypeText(loan.loanType)})`;
    }
  };

  const loans = (realEstateAssetId?: string, category?: Suggestion) => {
    const realEstateLoans = realEstateAssetId
      ? realEstateLoansStore
          .getRealEstateLoansByRealEstateAssetId(realEstateAssetId)
          .map((loan) => ({
            name: loanTitle(loan),
            id: loan.id as string,
            distanceAmount: distanceAmount(
              getMonthlyPayment(loan).neg().toNumber(),
              transactionState.transaction.value.amount
            ),
          }))
          .sort((a, b) => (a.distanceAmount < b.distanceAmount ? -1 : 1))
      : [];
    if (isOptional(TypeReference.realEstateLoan, category)) {
      return [{ name: "Aucun", id: "" }, ...realEstateLoans];
    }
    return realEstateLoans;
  };

  const getSupportingDocuments = computed(() =>
    documentsStore.documents
      .filter(({ tags = [] }) => tags.includes("supportingDocument"))

      .map((d) => ({
        name: `${d.metadata?.wording} - ${d.metadata?.amountTotal}€`, // Wordings must always exist
        id: d.id,
        amount: d.metadata?.amountTotal,
        date: d.metadata?.issuanceDate,
        distanceAmount: distanceAmount(
          d?.metadata?.amountTotal ?? 0,
          Math.abs(transactionState.transaction.value.amount)
        ),
        distanceDate: d.metadata?.issuanceDate
          ? Math.abs(
              new Date(d.metadata?.issuanceDate).getTime() -
                new Date(transactionState.transaction.date.operation).getTime()
            )
          : Number.MAX_SAFE_INTEGER,
      }))
      .sort((a, b) => {
        if (a.distanceAmount === b.distanceAmount) {
          return a.distanceDate - b.distanceDate;
        }
        return a.distanceAmount - b.distanceAmount;
      })
  );
  const supportingDocuments = (category?: Suggestion) => {
    let documents = getSupportingDocuments.value;
    if (
      (category ? category : transactionState.selectedCategory?.number) ===
      LedgerAccountEnum.N213000
    ) {
      documents = documents.filter((document) =>
        isSamePeriodAsAcquisition(document.date)
      );
    }
    if (isOptional(TypeReference.supportingDocument, category)) {
      return [{ name: "Aucun", id: "" }, ...documents];
    }
    return documents;
  };

  return {
    transactionState,
    // Data
    product,
    transactionAmountTaxDecomposition,
    categoriesList,
    // Store
    updateCategoriesList,
    // transactionState
    categories,
    isOpenCategorizationList,
    isOpenCategorizationDetailStep,
    isOpenCategorizationDuplicate,
    // Use
    addCategory,
    updateCategory,
    resetCategories,
    deleteCategory,
    getTransactionWithNewCategories,
    subDivide,
    getCategoryInfos,
    selectCategory,
    saveCategorization,
    validateSuggestion,
    validateCategory,
    addManualTransactionFlow,
    getMissingAmount,
    getAttribute,
    isTvaCollectedIncorrect,
    getRentalUnitFromRealEstateAssetId,
    isRequired,
    isOptional,
    // references
    fixedAssets,
    realEstateAssets,
    rentalAgreements,
    tenants,
    partners,
    loans,
    getSupportingDocuments,
    supportingDocuments,
  };
};
