import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { Address, addressSchema, IsoDate, RequireField, Verified } from "./Common.model";
import { ArchivedFields } from "./Archive.model";
import { Cga, cgaSchema } from "./Cga.model";
import { DeepPartial } from "./Config.model";
import { ServerError } from "../lib";
import { ACTIVITY_ERROR } from "./FunctionalError";
import { FileInfo } from "..";


export namespace ActivitiesModel {
  /**
   * * Types
   */
  export enum ActivityTypes {
    COMPANY = "company",
    OPERATOR = "operator",
  }

  export enum ActivityOperatorTypes {
    NATURAL_PERSON = "natural_person",
    LEGAL_PERSON = "legal_person", // Personne morale
  }
  export type ActivitiesFileInfoArray = {
    file?: FileInfo;
    file64?: string;
  }[]

  export interface Signature {
    typeSignature?: string;
    data?: string;
    signatureId?: string;
  }
  export const signatureSchema = new Schema<Signature>({
    typeSignature: String,
    data: String,
    signatureId: String,
  });
  // * Activity build fields
  type ActivityCommonFields<
    ActivityType extends ActivityTypes = ActivityTypes,
    IsArchived extends boolean = boolean
  > = {
    id: string;
    productId: string;
    type: ActivityType;

    address?: RequireField<Address, "street" | "city" | "zip">;
    phone?: string;

    /**
     * National identification number (SIRET) of the primary establishment.
     */
    siret?: string;
    siretStatus?: Verified;

    /**
     * National Activity Code of the company (4 digits + 1 letter)
     * 6820B pour "Location de terrains et d'autres biens immobiliers"
     */
    ape?: string;
    apeStatus?: Verified;

    legalName?: string; // Legal name (siren API)
    legalNature?: string; // Legal nature (6540 pour SCI)
    signature?: Signature;

    hasTva: boolean; // ! Not in BDD

    createdAt: string;
    updatedAt: string;
  } & ArchivedFields<IsArchived>;

  export type ActivityTypeCompanyFields = {
    type: ActivityTypes.COMPANY;
    name: string;
  };

  export type ActivityTypeOperatorFields<
    ActivityOperatorType extends ActivityOperatorTypes = ActivityOperatorTypes,
    ActivityOperatorHasCGA extends boolean = boolean
  > = {
    type: ActivityTypes.OPERATOR;
    operatorType: ActivityOperatorType;

    birthDate?: IsoDate;
    indieChecked: boolean;
    hasCga: ActivityOperatorHasCGA;
    signature?: Signature;

    activityAddress?: RequireField<Address, "street" | "city" | "zip">;
  } & (ActivityOperatorHasCGA extends true ? { cga: Cga } : { cga?: Cga }) &
    (
      | {
          operatorType: ActivityOperatorTypes.NATURAL_PERSON;
          lastName: string;
          firstName: string;
        }
      | {
          operatorType: ActivityOperatorTypes.LEGAL_PERSON;
          denomination: string;
          legalStatus: string;
        }
    );

  // * Activity
  export type Activity<
    ActivityType extends ActivityTypes = ActivityTypes,
    ActivityOperatorType extends ActivityOperatorTypes = ActivityOperatorTypes,
    IsArchived extends boolean = boolean
  > = ActivityCommonFields<ActivityType, IsArchived> &
    (ActivityTypeCompanyFields | ActivityTypeOperatorFields<ActivityOperatorType>);

  export type ActivityRepository<
    ActivityType extends ActivityTypes = ActivityTypes,
    ActivityOperatorType extends ActivityOperatorTypes = ActivityOperatorTypes,
    IsArchived extends boolean = boolean
  > = Omit<ActivityCommonFields<ActivityType, IsArchived>, "hasTva"> &
    (ActivityTypeCompanyFields | ActivityTypeOperatorFields<ActivityOperatorType>);

  export type ActivityCreate<
    ActivityType extends ActivityTypes = ActivityTypes,
    ActivityOperatorType extends ActivityOperatorTypes = ActivityOperatorTypes
  > = Omit<
    ActivityCommonFields<ActivityType>,
    | "id"
    | "productId"
    | "siretStatus"
    | "ape"
    | "apeStatus"
    | "legalName"
    | "legalNature"
    | "hasTva"
    | "isArchived"
    | "isArchivedAt"
    | "createdAt"
    | "updatedAt"
  > &
    (ActivityTypeCompanyFields | ActivityTypeOperatorFields<ActivityOperatorType>);

  export type ActivityCreateInternal<
    ActivityType extends ActivityTypes = ActivityTypes,
    ActivityOperatorType extends ActivityOperatorTypes = ActivityOperatorTypes
  > = Omit<
    ActivityCommonFields<ActivityType>,
    | "id"
    | "siretStatus"
    | "ape"
    | "apeStatus"
    | "legalName"
    | "legalNature"
    | "hasTva"
    | "isArchived"
    | "isArchivedAt"
    | "createdAt"
    | "updatedAt"
  > &
    (ActivityTypeCompanyFields | ActivityTypeOperatorFields<ActivityOperatorType>);
  export type ActivityCreateRepository<
    ActivityType extends ActivityTypes = ActivityTypes,
    ActivityOperatorType extends ActivityOperatorTypes = ActivityOperatorTypes
  > = Omit<
    ActivityCommonFields<ActivityType>,
    "id" | "hasTva" | "isArchived" | "isArchivedAt" | "createdAt" | "updatedAt"
  > &
    (ActivityTypeCompanyFields | ActivityTypeOperatorFields<ActivityOperatorType>);

  export type ActivityUpdate<
    ActivityType extends ActivityTypes = ActivityTypes,
    ActivityOperatorType extends ActivityOperatorTypes = ActivityOperatorTypes
  > = RequireField<
    DeepPartial<
      Omit<
        ActivityCommonFields<ActivityType>,
        | "productId"
        | "siretStatus"
        | "ape"
        | "apeStatus"
        | "legalName"
        | "legalNature"
        | "hasTva"
        | "isArchived"
        | "isArchivedAt"
        | "createdAt"
        | "updatedAt"
      >
    >,
    "id"
  > &
    DeepPartial<ActivityTypeCompanyFields | ActivityTypeOperatorFields<ActivityOperatorType>>;
  export type ActivityUpdateInternal<
    ActivityType extends ActivityTypes = ActivityTypes,
    ActivityOperatorType extends ActivityOperatorTypes = ActivityOperatorTypes,
    ActivityOperatorHasCGA extends boolean = boolean
  > = RequireField<
    DeepPartial<
      Omit<
        ActivityCommonFields<ActivityType>,
        "ape" | "apeStatus" | "legalName" | "legalNature" | "hasTva" | "createdAt" | "updatedAt"
      >
    >,
    "id"
  > &
    (
      | DeepPartial<ActivityTypeCompanyFields>
      | DeepPartial<ActivityTypeOperatorFields<ActivityOperatorType, ActivityOperatorHasCGA>>
    );
  export type ActivityUpdateRepository<
    ActivityType extends ActivityTypes = ActivityTypes,
    ActivityOperatorType extends ActivityOperatorTypes = ActivityOperatorTypes
  > = RequireField<DeepPartial<Omit<ActivityCommonFields<ActivityType>, "hasTva" | "createdAt" | "updatedAt">>, "id"> &
    DeepPartial<ActivityTypeCompanyFields | ActivityTypeOperatorFields<ActivityOperatorType>>;

  /**
   * * Mongo
   */
  export type ActivityDocument = ActivityRepository & Document<string>;
  const activitySchema = new Schema<ActivityDocument>(
    {
      // * Common
      _id: { type: String, default: () => ulid() },
      productId: { type: String },
      type: { type: String, enum: Object.values(ActivityTypes) },

      address: addressSchema,
      phone: { type: String },

      siret: { type: String },
      siretStatus: { type: String },

      ape: { type: String },
      apeStatus: { type: String },

      legalNature: { type: String },
      legalName: { type: String },

      isArchived: { type: Boolean },
      isArchivedAt: { type: String },

      // * Company
      name: { type: String },

      // * Operator
      // Common
      operatorType: { type: String, enum: Object.values(ActivityOperatorTypes) },
      indieChecked: { type: Boolean },
      hasCga: { type: Boolean },
      cga: cgaSchema,
      activityAddress: addressSchema,

      // Natural person
      lastName: { type: String },
      firstName: { type: String },
      birthDate: { type: String },

      // Legal person
      denomination: { type: String },
      legalStatus: { type: String },
      signature: { type: signatureSchema }
    },
    {
      timestamps: true,
      toJSON: {
        versionKey: false,
        virtuals: true,
        transform(doc, ret: ActivityDocument) {
          ret.id = ret._id;
          delete ret._id;
          return ret;
        },
      },
    }
  );
  export const ActivityModel = model<ActivityDocument>("Activity", activitySchema, "Activities");
}

// API
export namespace ActivitiesService {
  export type ListIn = Partial<Pick<ActivitiesModel.Activity, "productId">>;
  export type ListOut = ActivitiesModel.Activity[];

  export type GetIn = Pick<ActivitiesModel.Activity, "id">;
  export type GetOut = ActivitiesModel.Activity;

  export type UpdateIn = ActivitiesModel.ActivityUpdate;
  export type UpdateOut = ActivitiesModel.Activity;
 
  export type AddSignImageIn = Pick<ActivitiesModel.Activity,"id"| "productId" | "signature"> & { files: ActivitiesModel.ActivitiesFileInfoArray };
  export type AddSignImageOut = ActivitiesModel.Activity;

  export type DeleteImageSignatureIn =Pick<ActivitiesModel.Activity, "id" | "signature">;
  export type DeleteImageSignatureOut = ActivitiesModel.Activity;
}

// Lib
export namespace ActivitiesLib {
  export const isActivityType =
    <Type extends ActivitiesModel.ActivityTypes, Activity extends ActivitiesModel.Activity>(type: Type) =>
    (activity: Activity): activity is Activity & { type: Type } =>
      activity.type === type;
  export const isActivityOperatorType =
    <
      OperatorTypes extends ActivitiesModel.ActivityOperatorTypes,
      Activity extends ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.OPERATOR>
    >(
      operatorType: OperatorTypes
    ) =>
    (activity: Activity): activity is Activity & { operatorType: OperatorTypes } =>
      activity.operatorType === operatorType;

  export const getActivityName = <Activity extends ActivitiesModel.Activity>(activity: Activity): string => {
    if (isActivityType(ActivitiesModel.ActivityTypes.COMPANY)(activity)) {
      return activity.name;
    }
    if (isActivityType(ActivitiesModel.ActivityTypes.OPERATOR)(activity)) {
      if (isActivityOperatorType(ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON)(activity)) {
        return `${activity.lastName} ${activity.firstName}`;
      }
      if (isActivityOperatorType(ActivitiesModel.ActivityOperatorTypes.LEGAL_PERSON)(activity)) {
        return activity.denomination;
      }
    }
    throw new ServerError("Activity `type` or `operatorType` is not supported", 500, ACTIVITY_ERROR, { activity });
  };
}
