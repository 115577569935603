var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"rentalAgreementServiceModal"},[_c('v-card-title',{staticClass:"mb-4"},[_c('h4',[_vm._v("Pilotage automatisé des loyers")]),_c('v-spacer'),(_vm.rentalAgreement.id && !_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticClass:"modal-btn mr-5",attrs:{"disabled":_vm.editable,"color":"primary","depressed":""},on:{"click":function($event){return _vm.$emit('edit')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pencil-outline")]),_vm._v(" Modifier ")],1):_vm._e(),(_vm.showClose)?_c('v-btn',{attrs:{"color":"#F4655F","depressed":"","fab":"","height":"30","width":"30"},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"color":"#fff","size":"20"}},[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-card-text',{staticClass:"pa-4"},[(_vm.rentalAgreement.id && _vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticClass:"modal-btn",attrs:{"disabled":_vm.editable,"color":"primary","depressed":""},on:{"click":function($event){return _vm.$emit('edit')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pencil-outline")]),_vm._v(" Modifier ")],1):_vm._e(),_c('v-form',{ref:"form",staticClass:"modal-form",on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('div',{staticClass:"section pa-sm-2 pa-md-4 pb-4"},[_c('div',[_c('h3',[_vm._v("Appel de loyer")]),_c('div',{staticClass:"mb-5"},[_vm._v(" Votre locataire recevra l'appel de loyer par email à la date : ")]),_c('span',{staticClass:"rent-call-options"},[_c('v-select',{staticClass:"d-inline-flex select-inline",attrs:{"label":"Date d'envoi l'appel","items":_vm.DELAY_BEFORE_SEND_MAIL,"rules":[
                  function () { return !(
                      !!_vm.rentalAgreement.options.rentCallHasToCopyActivity &&
                      !_vm.rentalAgreement.options.rentCallNumberDayBeforeRent
                    ); } ],"disabled":!_vm.editable,"item-text":"text","item-value":"value","outlined":"","required":""},model:{value:(_vm.rentalAgreement.options.rentCallNumberDayBeforeRent),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "rentCallNumberDayBeforeRent", $$v)},expression:"rentalAgreement.options.rentCallNumberDayBeforeRent"}}),_c('v-select',{staticClass:"d-inline-flex select-inline",attrs:{"label":"Date de paiement","items":_vm.DAY_OF_THE_MONTH,"rules":[
                  function () { return !(
                      !!_vm.rentalAgreement.options.rentCallHasToCopyActivity &&
                      !_vm.rentalAgreement.options.rentPaymentDayOfTheMonth
                    ); } ],"disabled":!_vm.editable,"item-text":"text","item-value":"value","outlined":"","required":""},model:{value:(_vm.rentalAgreement.options.rentPaymentDayOfTheMonth),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "rentPaymentDayOfTheMonth", $$v)},expression:"rentalAgreement.options.rentPaymentDayOfTheMonth"}})],1),_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"color":"primary","disabled":!_vm.editable,"label":"Me tenir informé"},model:{value:(_vm.rentalAgreement.options.rentCallHasToCopyActivity),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "rentCallHasToCopyActivity", $$v)},expression:"rentalAgreement.options.rentCallHasToCopyActivity"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('a',_vm._g({staticClass:"preview-btn",attrs:{"href":"#"}},on),[_vm._v(" Afficher l'aperçu de l'email")])]}}])},[_c('BadgeFeatureSoon')],1),_c('div',{staticClass:"rent-call-signature"},[_c('br'),_c('p',{staticClass:"mr-3"},[_vm._v(" Souhaitez-vous intégrer la signature de votre SCI aux appels générés avec Ownily ? ")]),_c('YesNoSwitch',{attrs:{"disabled":!_vm.rentalAgreement.options.rentCallActivated || !_vm.editable},model:{value:(_vm.rentalAgreement.options.rentCallSignature),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "rentCallSignature", $$v)},expression:"rentalAgreement.options.rentCallSignature"}})],1),(!_vm.activityHasSignature)?_c('div',[_c('p',[_vm._v("Vous n'avez pas encore paramétré votre signature ?")]),_c('v-btn',{attrs:{"color":"primary","to":{
                  name: _vm.ROUTE_NAMES.Activities,
                  query: { focus: 'signature-section' },
                },"disabled":!_vm.editable}},[_vm._v(" Configurer la signature")])],1):_vm._e()],1),_c('ToggleButton',{attrs:{"disabled":!_vm.editable},on:{"toggle":_vm.checkPermissions},model:{value:(_vm.rentalAgreement.options.rentCallActivated),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "rentCallActivated", $$v)},expression:"rentalAgreement.options.rentCallActivated"}})],1),_c('div',{staticClass:"section pa-sm-2 pa-md-4 pb-4"},[_c('div',[_c('h3',[_vm._v("Quittance de loyer")]),_c('div',[_vm._v(" Votre locataire recevra quittance dès qu'un loyer encaissé sera détecté sur Ownily ")]),(_vm.editable && _vm.rentalAgreement.options.rentReceiptActivated)?_c('v-alert',{staticClass:"mt-4",attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(" Cette fonctionnalité nécessite la synchronisation du compte bancaire de la SCI. ")]):_vm._e(),_c('v-checkbox',{attrs:{"color":"primary","disabled":!_vm.editable,"label":"Me tenir informé"},model:{value:(_vm.rentalAgreement.options.rentReceiptHasToCopyActivity),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "rentReceiptHasToCopyActivity", $$v)},expression:"rentalAgreement.options.rentReceiptHasToCopyActivity"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('a',_vm._g({staticClass:"preview-btn",attrs:{"href":"#"}},on),[_vm._v(" Afficher l'aperçu de l'email")])]}}])},[_c('BadgeFeatureSoon')],1),_c('div',[_c('div',{staticClass:"rent-receipt-signature"},[_c('br'),_c('p',{staticClass:"mr-3"},[_vm._v(" Souhaitez-vous intégrer la signature de votre SCI aux quittances générées avec Ownily ? ")]),_c('YesNoSwitch',{attrs:{"disabled":!_vm.rentalAgreement.options.rentReceiptActivated || !_vm.editable},model:{value:(_vm.rentalAgreement.options.rentReceiptSignature),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "rentReceiptSignature", $$v)},expression:"rentalAgreement.options.rentReceiptSignature"}})],1),(!_vm.activityHasSignature)?_c('div',[_c('p',[_vm._v("Vous n'avez pas encore paramétré votre signature ?")]),_c('v-btn',{attrs:{"color":"primary","to":{
                    name: _vm.ROUTE_NAMES.Activities,
                    query: { focus: 'signature-section' },
                  },"disabled":!_vm.editable}},[_vm._v(" Configurer la signature")])],1):_vm._e()])],1),_c('ToggleButton',{attrs:{"disabled":!_vm.editable},on:{"toggle":_vm.checkPermissions},model:{value:(_vm.rentalAgreement.options.rentReceiptActivated),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "rentReceiptActivated", $$v)},expression:"rentalAgreement.options.rentReceiptActivated"}})],1),_c('div',{staticClass:"section pa-sm-2 pa-md-4 pb-4"},[_c('div',[_c('h3',[_vm._v("Relance pour loyer impayé")]),_c('div',{staticClass:"mb-5"},[_vm._v(" Votre locataire recevra une relance pour lui rappeler de payer son loyer. ")]),(
                _vm.editable &&
                _vm.rentalAgreement.options.reminderUnpaidRentActivated
              )?_c('v-alert',{staticClass:"mt-4",attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(" Cette fonctionnalité nécessite la synchronisation du compte bancaire de la SCI. ")]):_vm._e(),_c('span',{staticClass:"rent-call-options"},[_c('v-select',{staticClass:"d-inline-flex select-inline",attrs:{"label":"Date d'envoi de la relance","items":_vm.REMINDER_AFTER_SEND_MAIL,"rules":[
                  function () { return !(
                      !!_vm.rentalAgreement.options.reminderUnpaidRentActivated &&
                      !_vm.rentalAgreement.options.reminderRentCallNumberDayAfter
                    ); } ],"disabled":!_vm.editable,"item-text":"text","item-value":"value","outlined":"","required":""},model:{value:(
                  _vm.rentalAgreement.options.reminderRentCallNumberDayAfter
                ),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "reminderRentCallNumberDayAfter", $$v)},expression:"\n                  rentalAgreement.options.reminderRentCallNumberDayAfter\n                "}}),_c('v-select',{staticClass:"d-inline-flex select-inline",attrs:{"label":"Date de paiement","items":_vm.DAY_OF_THE_MONTH,"rules":[
                  function () { return !(
                      !!_vm.rentalAgreement.options
                        .unpaidRentCallHasToCopyActivity &&
                      !_vm.rentalAgreement.options.rentPaymentDayOfTheMonth
                    ); } ],"disabled":!_vm.editable,"item-text":"text","item-value":"value","outlined":"","required":""},model:{value:(_vm.rentalAgreement.options.rentPaymentDayOfTheMonth),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "rentPaymentDayOfTheMonth", $$v)},expression:"rentalAgreement.options.rentPaymentDayOfTheMonth"}})],1),_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"color":"primary","disabled":!_vm.editable,"label":"Me tenir informé"},model:{value:(
                _vm.rentalAgreement.options.unpaidRentCallHasToCopyActivity
              ),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "unpaidRentCallHasToCopyActivity", $$v)},expression:"\n                rentalAgreement.options.unpaidRentCallHasToCopyActivity\n              "}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('a',_vm._g({staticClass:"preview-btn",attrs:{"href":"#"}},on),[_vm._v(" Afficher l'aperçu de l'email")])]}}])},[_c('BadgeFeatureSoon')],1),_c('div',{staticClass:"unpaid-rent-call-signature"},[_c('br'),_c('p',{staticClass:"mr-3"},[_vm._v(" Souhaitez-vous intégrer la signature de votre SCI aux relances générées avec Ownily ? ")]),_c('YesNoSwitch',{attrs:{"disabled":!_vm.rentalAgreement.options.reminderUnpaidRentActivated ||
                  !_vm.editable},model:{value:(_vm.rentalAgreement.options.unpaidRentCallSignature),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "unpaidRentCallSignature", $$v)},expression:"rentalAgreement.options.unpaidRentCallSignature"}})],1),(!_vm.activityHasSignature)?_c('div',[_c('p',[_vm._v("Vous n'avez pas encore paramétré votre signature ?")]),_c('v-btn',{attrs:{"color":"primary","to":{ name: _vm.ROUTE_NAMES.Activities },"disabled":!_vm.editable}},[_vm._v(" Configurer la signature")])],1):_vm._e()],1),_c('ToggleButton',{attrs:{"disabled":!_vm.editable},on:{"toggle":_vm.checkPermissions},model:{value:(_vm.rentalAgreement.options.reminderUnpaidRentActivated),callback:function ($$v) {_vm.$set(_vm.rentalAgreement.options, "reminderUnpaidRentActivated", $$v)},expression:"rentalAgreement.options.reminderUnpaidRentActivated"}})],1),_c('div',{staticClass:"rentalAgreementServiceModal-button-section mt-3"},[(!_vm.hidePagination)?_c('CustomLabelButton',{on:{"click":function($event){return _vm.$emit('previous')}}},[_vm._v("Mon locataire")]):_vm._e(),(_vm.editable)?_c('v-btn',{staticClass:"modal-btn",attrs:{"loading":_vm.validateInProgress,"color":"primary","depressed":"","type":"submit","id":"rentalAgreement-form-validate"}},[_vm._v(" Valider ")]):_vm._e()],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }