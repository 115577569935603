/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios from "axios";
import { parseString } from "xml2js";
import { first, split } from "lodash";
import { IsoDate } from "../models/Common.model";
import { RentIndexationTypeParamEnum } from "../models/RentalAgreement.model";

export type InseeData = {
  indexData: string;
  code: string;
  dateAppearance?: IsoDate;
};

const callInseeAPI = (irlType: RentIndexationTypeParamEnum) => {
  let url = ""
  switch (irlType) {
    case RentIndexationTypeParamEnum.IRL_metro:
      url = "https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/001515333"
      break;
    case RentIndexationTypeParamEnum.IRL_corse:
      url = "https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/010760507"
      break;
    case RentIndexationTypeParamEnum.IRL_outre:
      url = "https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/010760509"
      break;
    default:
      url = "https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/001515333"
      break;
  }

  return new Promise<Record<string, any>>((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer 5c0dacc3-7f85-366a-9459-aaf395def8a7`,
          Accept: "application/vnd.sdmx.structurespecificdata+xml;version=2.1",
        },
      })
      .then((response) => {
        parseString(response.data, (err, result) => {
          if (err) {
            reject(err);
          }
          resolve(result);
        });
      })
      .catch((err) => reject(err));
  });
};

const getInseeData = async (irlType: RentIndexationTypeParamEnum) => {
  const data = await callInseeAPI(irlType);
  const observations =
    data["message:StructureSpecificData"]["message:DataSet"][0]["Series"][0][
      "Obs"
      ];
  const inseeData: InseeData[] = [];
  observations.forEach((_obs: any) => {
    const obs = _obs["$"];
    const timePeriod = split(obs["TIME_PERIOD"], "-");
    let dateAppearance;
    const year = Number(first(timePeriod));
    const trimester = timePeriod[1].replace("Q", "T");
    const value = Number(obs["OBS_VALUE"]);
    const code = obs["OBS_STATUS"];
    const date = obs["DATE_JO"];
    if (date) {
      dateAppearance = obs["DATE_JO"];
    }
    inseeData.push({ indexData : `${year}, ${trimester}, ${value}`, code, dateAppearance });
  });
  return inseeData;
};

/**
 * API to get last INSEE publication
 */
export const getCurrentInseeData = async (irlType: RentIndexationTypeParamEnum): Promise<InseeData[]> => {
  const dataFromAPI = await getInseeData(irlType);
  if (dataFromAPI.length) {
    return dataFromAPI;
  }
  return [];
};
