
































































































































































































































































































































































































import { defineComponent, ref, PropType } from "@vue/composition-api";
import SectionTitle from "@/components/atom/SectionTitle.vue";
import { YesNoSwitch } from "@/components/atom/switch";
import DatePicker from "@/components/atom/DatePicker.vue";
import { format } from "date-fns";
import { RentalAgreement, Tenant, TenantTypeEnum } from "@edmp/api";
import { useRents } from "./rents.usable";
import { VForm } from "@/models";
import { tenantsStore } from "@/store";

export default defineComponent({
  name: "RealEstateRentsTenants",
  components: { DatePicker, YesNoSwitch, SectionTitle },
  props: {
    rentalAgreement: {
      type: Object as PropType<RentalAgreement>,
      required: true,
    },
    tenants: {
      type: Array as PropType<Tenant[]>,
      required: true,
    },
  },
  setup(props, context) {
    const {
      addTenant,
      tenantType,
      typeRentalAgreementItems,
      rentalAgreementRules,
    } = useRents();

    const goBack = () => {
      context.emit("goBack");
    };

    const goNext = () => {
      if ((context.refs.form as VForm).validate()) {
        context.emit("goNext");
      }
    };

    const today = ref(format(new Date(), "yyyy-MM-dd"));

    const selectedTenantId = ref("");
    const selectedTenantIndex = ref("");
    const displayDeleteTenantModal = ref(false);

    const openDeleteTenantModal = (tenantId, index) => {
      selectedTenantIndex.value = index;
      if (tenantId) {
        selectedTenantId.value = tenantId;
        displayDeleteTenantModal.value = true;
      } else context.emit("deleteTenant", index);
    };

    const deleteTenant = () => {
      tenantsStore.deleteTenant(selectedTenantId.value);
      context.emit("deleteTenant", selectedTenantIndex);
      displayDeleteTenantModal.value = false;
    };

    return {
      today,
      goBack,
      goNext,
      typeRentalAgreementItems,
      tenantType,
      addTenant,
      TenantTypeEnum,
      openDeleteTenantModal,
      selectedTenantId,
      displayDeleteTenantModal,
      deleteTenant,
      rentalAgreementRules,
    };
  },
});
