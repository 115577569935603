


























































































































































import {
  defineComponent,
  computed,
  onMounted,
  watch,
  Ref,
  ref,
} from "@vue/composition-api";
import { ArticleEnum } from "@/composables/crisp.usable";
import { HelpingMessage } from "@/components/atom/helping";
import NewDocumentsTable from "./NewDocumentsTable.vue";
import DocumentsPanel from "./DocumentsPanel.vue";
import ModelsLibrary from "./ModelsLibrary.vue";
import AddDocumentsModal from "./AddDocumentsModal.vue";
import PageHeader from "../../atom/PageHeader.vue";
import { useDocuments } from "./useDocuments";
import { accountingPeriodsStore, coreStore, subscriptionsStore } from "@/store";
import { TaxRegime } from "@edmp/api";
import { ROUTE_NAMES } from "@/router/routes";
import { ForbiddenError, subject } from "@casl/ability";
import { ability } from "@/services";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import router from "@/router";

export default defineComponent({
  name: "Documents",
  components: {
    HelpingMessage,
    PageHeader,
    NewDocumentsTable,
    DocumentsPanel,
    AddDocumentsModal,
    ModelsLibrary,
  },
  setup(_, context) {
    const headers = [
      { text: "Libellé", value: "name" },
      { text: "Bien", value: "realEstateAsset" },
      { text: "Date de génération", value: "createdAt" },
      { text: "Année d'exercice", value: "accountingPeriod" },
      { text: "Format", value: "type" },
      { text: "Supprimer", value: "delete", sortable: false },
    ];

    const headersRent = [
      { text: "Libellé", value: "name" },
      { text: "Locataire", value: "tenant" },
      { text: "Bien", value: "realEstateAsset" },
      { text: "Période", value: "rentPeriod" },
      { text: "Format", value: "type" },
      { text: "Supprimer", value: "delete", sortable: false },
    ];

    const showAddDocumentModal: Ref<boolean> = ref(false);
    const { documents, getDocuments } = useDocuments();

    const focus: Ref<string> = ref("");

    const isIS = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.IS_2065
    );
    const isLMNP = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
    );
    const subscription = computed(() => {
      return subscriptionsStore.getCurrentSubscription;
    });
    const checkPermissions = () => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "visit",
          subject("ModelsLibrary", {})
        );
        router.push({ name: ROUTE_NAMES.ModelsLibrary });
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
      }
    };
    async function init() {
      documents.value = [];

      getDocuments();

      if (context.root.$route.query["focus"]) {
        focus.value = context.root.$route.query["focus"].toString();
        setTimeout(() => {
          try {
            context.root.$vuetify.goTo(`#${focus.value}`, {
              offset: 12,
            });
          } catch (err) {
            // do nothing
          }
        }, 500);
      }
    }

    onMounted(() => {
      init();
    });
    watch(
      () => accountingPeriodsStore.currentId,
      () => init(),
      { deep: true }
    );

    return {
      showAddDocumentModal,
      documents,
      getDocuments,
      headers,
      headersRent,
      focus,
      ArticleEnum,
      isIS,
      isLMNP,
      productAndPartners: computed(() =>
        documents.value.filter(
          (doc) => doc.tags && doc.tags.includes("product")
        )
      ),
      comptability: computed(() =>
        documents.value.filter(
          (doc) => doc.tags && doc.tags.includes("accounting")
        )
      ),
      rent: computed(() =>
        documents.value.filter(
          (doc) =>
            doc.tags &&
            doc.tags.includes("rental-agreement") &&
            doc.tags.includes("receipt")
        )
      ),
      fiscality: computed(() =>
        documents.value.filter((doc) => doc.tags && doc.tags.includes("tax"))
      ),
      events: computed(() =>
        documents.value.filter((doc) => doc.tags && doc.tags.includes("event"))
      ),
      realEstateAsset: computed(() =>
        documents.value.filter(
          (doc) => doc.tags && doc.tags.includes("real-estate-asset")
        )
      ),
      subscription,
      checkPermissions,
      ROUTE_NAMES,
    };
  },
});
